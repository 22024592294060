import pick from 'lodash.pick';

export const defaultKeys = [
  'region',
  'region_type',
  'city',
  'city_type',
  'settlement',
  'settlement_type',
  'street_with_type',
  'house',
  'house_type',
  'postal_code',
  'geo_lat',
  'geo_lon',
  'block',
  'block_type',
  'kladr_id',
];

export const resultKeys = [
  'region_with_type',
  'city_with_type',
  'settlement_with_type',
  'street_with_type',
  'house',
  'house_type',
  'postal_code',
  'geo_lat',
  'geo_lon',
  'block',
  'block_type',
  'kladr_id',
];

export default ({ prefix, container }) => {
  if (!prefix || !container) return {};

  const addr = pick(container, defaultKeys);
  addr.region_with_type = [addr.region, addr.region_type].join(' ').trim();
  addr.city_with_type = [addr.city, addr.city_type].join(' ').trim();
  addr.settlement_with_type = [addr.settlement, addr.settlement_type].join(' ').trim();

  const preRes = pick(addr, resultKeys);

  const result = {};

  for (const [key, value] of Object.entries(preRes)) {
    result[`${prefix}_${key}`] = value === null ? '' : value;
  }

  return result;
};
