<template>
  <YmiField
    :label="props.label"
    semibold
  >
    <AutoCompleteDadata
      :model-value="documentStore.cityKLADR_residence_merged"
      options="city"
      size="sm"
      :error="validationStore.cityKLADR_residence"
      :position="props.position"
      data-test="input_residenceCity"
      @update:model-value="
        (value) => {
          documentStore.$patch({
            cityKLADR_residence_region_with_type: '',
            cityKLADR_residence_city_with_type: value,
            cityKLADR_residence_settlement_with_type: '',
          });
        }
      "
      @chosen="
        (data) => {
          documentStore.$patch({
            ...transformDadata({
              prefix: 'cityKLADR_residence',
              container: data.data,
            }),
            cityKLADR_residence_dadata: data,
          });

          documentStore.GetInfo4Appeal();
          handleChoose(data?.data?.kladr_id);
        }
      "
      @clear="handleClear"
    />
  </YmiField>
</template>

<script setup>
import transformDadata from '~/helpers/transformDadata';
import { useDocumentStore } from '~/stores/document';
import { useValidationStore } from '~/stores/validation';

const emit = defineEmits(['chosen']);

const props = defineProps({
  position: {
    type: String,
    default: 'bottom',
  },
  label: {
    type: String,
    default: 'Город проживания',
  },
});

const documentStore = useDocumentStore();
const validationStore = useValidationStore();
const { $api } = useNuxtApp();

const handleChoose = async (kladr_id) => {
  if (!kladr_id) {
    return validationStore.fail(['cityKLADR_residence']);
  }

  emit('chosen');

  const { data } = await $api.web.CheckResidence(kladr_id);

  validationStore[data?.data?.ok ? 'ok' : 'fail'](['cityKLADR_residence']);
};

const handleClear = () => {
  documentStore.$patch({
    cityKLADR_residence_dadata: null,
  });
  documentStore.$patch({
    cityKLADR_residence_dadata: {},
  });
};
</script>
