<template>
  <div class="relative w-full rounded-lg text-sm transition-all">
    <input
      ref="input"
      v-maska
      :data-maska="maska"
      :value="modelValue"
      class="input w-full pr-6 transition-all focus:outline-none"
      :class="{
        [`input-${size}`]: true,
        'border-gray-300 bg-base-100 focus:border-gray-800 focus:outline-none dark:border-gray-500 dark:focus:border-gray-200':
          !error,
        'border-red-500': error,
      }"
      :disabled="disabled"
      @focus="inputFocused = true"
      @input="
        (event) => {
          if (event.bubbles) {
            remoteData = [];
            if (inputFocused) {
              showOptions = true;
              focusedItem = -1;
              emit('update:modelValue', event.target.value);
              searchTerm = event.target.value;
            }
            handleInput(event.target.value);
          }
        }
      "
      @keydown.tab="
        inputFocused = false;
        showOptions = false;
      "
      @keydown.down="pressDown"
      @keydown.up.prevent="pressUp"
      @keyup.enter="handleEnter"
    />
    <span
      v-if="modelValue && showResetButton && modelValue"
      class="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
      @click.prevent="reset"
    >
      x
    </span>
    <div class="relative">
      <div
        v-if="showOptions && inputFocused"
        ref="suggestions"
        tabindex="-1"
        class="absolute z-50 max-h-40 w-full overflow-hidden overflow-y-scroll rounded-md border border-gray-300 bg-base-100 shadow-md"
        :class="[position === 'top' && 'bottom-0 mb-8']"
        @focusout="showOptions = false"
      >
        <div>
          <div
            v-if="searchResults.length"
            ref="suggestionsContainer"
            class="flex flex-col py-1"
            @click="handleClick($event.target)"
          >
            <div
              v-for="(item, index) in searchResults"
              :key="index"
              class="cursor-pointer px-3 py-2 hover:bg-base-200"
              :class="{ 'bg-base-200': focusedItem === index }"
              :data-item="JSON.stringify(item)"
              v-html="formatSuggestion(item)"
            />
          </div>
          <div
            v-if="!isLoading && !searchResults.length"
            class="px-3 py-2 text-center"
          >
            {{ noItemsFoundText }}
          </div>
          <div
            v-if="isLoading"
            class="px-3 py-2 text-center"
          >
            {{ isLoadingText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core';
import debounce from '~/helpers/debounce';
import get from '~/helpers/get';
import compact from '~/helpers/compact';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  options: {
    type: String,
    required: false,
    default: 'region',
  },
  error: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    required: false,
    default: '',
  },
  noItemsFoundText: {
    type: String,
    required: false,
    default: '💤 Ничего не найдено',
  },
  isLoadingText: {
    type: String,
    required: false,
    default: '🤖 Поиск...',
  },
  showResetButton: {
    type: Boolean,
    default: true,
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'md',
    required: false,
    validate: (value) => ['xs', 'sm', 'md', 'lg'].includes(value),
  },
  maska: {
    type: String,
    default: '',
  },
  city: {
    type: [Object, String],
    default: () => ({}),
  },
  position: {
    type: String,
    default: 'bottom',
  },
});

const emit = defineEmits(['update:modelValue', 'chosen', 'clear']);

const { $api } = useNuxtApp();

const isLoading = ref(false);
const remoteData = ref([]);
const showOptions = ref(false);
const chosenOption = ref('');
const searchTerm = ref('');
const focusedItem = ref(-1);
const inputFocused = ref(false);
const suggestions = ref(null);
const suggestionsContainer = ref(null);
const input = ref(null);

const searchResults = computed(() => remoteData.value || []);

onClickOutside(suggestions, (event) => {
  if (suggestions.value && !suggestions.value.contains(event.target)) {
    showOptions.value = false;
  }
});

const scroll = () => {
  const offset = Number(
    get(suggestionsContainer, `value.children[${focusedItem.value}].offsetTop`),
  );

  if (typeof offset === 'undefined') return;
  suggestions.value.scrollTop = offset - 40;
};

const pressDown = () => {
  if (
    props.modelValue &&
    showOptions.value &&
    focusedItem.value > -2 &&
    focusedItem.value < searchResults.value.length - 1
  ) {
    focusedItem.value = focusedItem.value + 1;
    scroll();
  }
};

const pressUp = () => {
  if (props.modelValue && showOptions.value && focusedItem.value > 0) {
    focusedItem.value = focusedItem.value - 1;
    scroll();
  }
};

const reset = () => {
  emit('clear');
  emit('update:modelValue', '');
  chosenOption.value = '';
};

const getLocations = ({ bounds, parent }) => {
  const payload = {};

  if (!parent) return payload;

  if (bounds === 'street') {
    const city_fias_id = parent.data?.city_fias_id;
    if (city_fias_id) payload.city_fias_id = city_fias_id;
    const region_fias_id = parent.data?.region_fias_id;
    if (region_fias_id) payload.region_fias_id = region_fias_id;
    const settlement_fias_id = parent.data?.settlement_fias_id;
    if (settlement_fias_id) payload.settlement_fias_id = settlement_fias_id;
    const area_fias_id = parent.data?.area_fias_id;
    if (area_fias_id) payload.area_fias_id = area_fias_id;
  }

  if (bounds === 'house') {
    const street_fias_id = parent.data?.street_fias_id;
    if (street_fias_id) payload.street_fias_id = street_fias_id;
  }

  return [payload];
};

const remoteRequest = async (value) => {
  try {
    isLoading.value = true;
    switch (props.options) {
      case 'region': {
        const { data } = await $api.web.address({ query: value });
        return data.data.suggestions;
      }
      case 'city': {
        const { data } = await $api.web.address({
          query: value,
          from_bound: { value: 'city' },
          to_bound: { value: 'settlement' },
          locations: [{}],
        });
        return data.data.suggestions;
      }
      case 'street': {
        const { data } = await $api.web.address({
          query: value,
          from_bound: { value: 'settlement' },
          to_bound: { value: 'street' },
          locations: getLocations({ bounds: props.options, parent: props.city }),
        });
        return data.data.suggestions;
      }
      case 'house': {
        const { data } = await $api.web.address({
          query: value,
          from_bound: { value: 'street' },
          to_bound: { value: 'house' },
          locations: [getLocations({ bounds: props.options, parent: props.city })],
        });
        return data.data.suggestions;
      }
      case 'first_name': {
        const { data } = await $api.web.fio({ query: value, parts: ['NAME'] });
        return data.data.suggestions;
      }
      case 'secondary_name': {
        const { data } = await $api.web.fio({ query: value, parts: ['PATRONYMIC'] });
        return data.data.suggestions;
      }
      case 'last_name': {
        const { data } = await $api.web.fio({ query: value, parts: ['SURNAME'] });
        return data.data.suggestions;
      }
      case 'fms_unit': {
        const { data } = await $api.web.fmsUnit({ query: value });
        return data.data.suggestions;
      }
      default: {
        const { data } = await $api.web.address({ query: value });
        return data.data.suggestions;
      }
    }
  } catch (err) {
    return [];
  } finally {
    isLoading.value = false;
  }
};

const handleInput = debounce(async function (val) {
  if (val) {
    remoteData.value = await remoteRequest(val);
  }
}, 250);

const formatOption = (option) => {
  if (props.options === 'codePassport') {
    return get(option, 'data.code');
  }

  const defaultTemplate = get(option, 'value') || '';

  if (props.options === 'street') {
    const res = compact([
      get(option, 'data.settlement_with_type'),
      get(option, 'data.street_with_type'),
    ]).join(', ');

    return res.length ? res : defaultTemplate;
  }

  if (props.options === 'house') {
    const res = compact([
      compact([get(option, 'data.house_type'), get(option, 'data.house')]).join(' '),
      compact([get(option, 'data.block_type'), get(option, 'data.block')]).join(' '),
    ]).join(', ');

    return res.length ? res : defaultTemplate;
  }

  return defaultTemplate;
};

const handleClick = (element) => {
  if (!element) return;
  if (typeof element.closest !== 'function') return;
  const el = element.closest('[data-item]');
  if (!el) return;
  const dataset = el.dataset;
  if (!dataset || !dataset.item) return;
  const item = JSON.parse(dataset.item);
  if (!item) return;

  const option = { ...item, value: formatOption(item) };
  emit('update:modelValue', option.value);
  emit('chosen', option);
  chosenOption.value = option.value;
  showOptions.value = false;
};

const handleEnter = () => {
  if (props.modelValue && showOptions.value) {
    const el = get(suggestionsContainer, `value.children[${focusedItem.value}]`);
    if (!el) return;
    handleClick(el);
  }
};

const formatSuggestion = (data) => {
  let title, subtitle;

  if (props.options === 'fms_unit') {
    return `<div class="flex items-center gap-2"><div class="badge badge-outline w-[80px]">${get(
      data,
      'data.code',
    )}</div><div class="max-w-[80%]">${get(data, 'value')}</div></div>`;
  }

  if (props.options === 'city') {
    title = compact([
      get(data, 'data.settlement_with_type'),
      get(data, 'data.city_with_type'),
      get(data, 'data.city_district_with_type'),
    ]).join(', ');
    subtitle = compact([get(data, 'data.area_with_type'), get(data, 'data.region_with_type')]).join(
      ', ',
    );
  }

  if (props.options === 'street') {
    title = compact([get(data, 'data.street_type'), get(data, 'data.street')]).join(', ');
    subtitle = compact([
      get(data, 'data.settlement_with_type'),
      get(data, 'data.city_with_type'),
      get(data, 'data.city_district_with_type'),
    ]).join(', ');
  }

  if (props.options === 'house') {
    title = compact([
      compact([get(data, 'data.house_type'), get(data, 'data.house')]).join(' '),
      compact([get(data, 'data.block_type'), get(data, 'data.block')]).join(' '),
    ]).join(', ');
    subtitle = get(data, 'data.street_with_type');
  }

  return title && subtitle
    ? `<div><span>${title}</span></div><div><span class="text-sm text-gray-500">${subtitle}</span></div>`
    : get(data, 'value');
};
</script>
